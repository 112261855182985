import { GetPartRequestOverviewQuery } from '@graphql/graphql'
import React from 'react'

import ActiveFacets from '@components/ActiveFacets'
import SelectFacets from '@components/SelectFacets'
import StickyFilters from '@components/StickyFilters'

enum PartRequestFilterKeys {
	status = 'status',
	company = 'company'
}

export interface PartRequestFilters {
	[PartRequestFilterKeys.status]?: string[]
	[PartRequestFilterKeys.company]?: string[]
}

interface PartRequestOverviewFiltersProps {
	facets: GetPartRequestOverviewQuery['quotations']['facets']
	activeFilters: PartRequestFilters
	onChangeFilters: (filters: PartRequestFilters) => void
	statusFilterLabel: string
	companyFilterLabel: string
	noSearchResultsLabel: string
	filterSearchPlaceholder: string
}

const PartRequestOverviewFilters = ({
	facets: facetsData,
	activeFilters,
	onChangeFilters,
	statusFilterLabel,
	companyFilterLabel,
	noSearchResultsLabel,
	filterSearchPlaceholder
}: PartRequestOverviewFiltersProps) => {
	const facets = [
		{
			key: PartRequestFilterKeys.status,
			label: statusFilterLabel,
			options: facetsData?.status
		},
		{
			key: PartRequestFilterKeys.company,
			label: companyFilterLabel,
			search: true,
			options: facetsData?.company || []
		}
	].filter(Boolean)

	return (
		<StickyFilters>
			<SelectFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
				noSearchResultsLabel={noSearchResultsLabel}
				filterSearchPlaceholder={filterSearchPlaceholder}
			/>

			<ActiveFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
			/>
		</StickyFilters>
	)
}

export default PartRequestOverviewFilters

import React from 'react'

import { Quotation } from '@graphql/graphql'

import { IfilterItem } from '@damen/ui/lib/cjs/components/Filter/Group/types'
import { TableHead } from '@components/Table/types'

import { validatePagefilterOption } from '@components/GenericOverview/helpers'
import {
	FilterValueResolver,
	SortingType,
	TableBodyData,
	SortingValueResolver
} from '@components/GenericOverview/types'
import Status from '@components/UI/Status/index'

import caseStatusColorMapping from '@utils/colors/caseStatusColorMapping'

import {
	PartRequestPageFilters,
	GoToDetailFnPartRequest,
	PartRequestData,
	PartRequestFilterNames,
	PartRequestColumnKeys,
	PartRequestColumnResolver
} from './types'

// Resolvers
export const filterResolver: FilterValueResolver = {
	status: (caseModel: Quotation) => caseModel.status.name
}

export const sortingResolver: SortingValueResolver = {
	dateFormatted: 'createdAt',
	requestedBy: 'requestedBy.name',
	status: 'status',
	title: 'title'
}

export const tableColumnResolver: PartRequestColumnResolver = {
	externalId: PartRequestColumnKeys.externalId,
	title: PartRequestColumnKeys.title,
	dateFormatted: PartRequestColumnKeys.dateFormatted,
	requestedBy: PartRequestColumnKeys.requestedBy,
	status: PartRequestColumnKeys.status
}

// Helper functions
// Map data from request to filter_selected
// Adding filters to filter data can be done here 👇
export const mapFilters = (
	items: PartRequestData[],
	resolver: FilterValueResolver,
	names: PartRequestFilterNames
): IfilterItem[] => {
	if (!items) {
		return []
	}

	const pageFilter: PartRequestPageFilters = {
		status: {
			label: names.status,
			name: PartRequestColumnKeys.status,
			search: false,
			options: []
		}
	}

	const tempStatus: string[] = []

	items.forEach((item) => {
		const status = resolver.status(item) as string

		validatePagefilterOption(pageFilter.status, tempStatus, status)
	})

	return Object.keys(pageFilter).map((item: string) => {
		const filterItem = pageFilter[item]
		return {
			label: filterItem.label,
			name: filterItem.name,
			search: filterItem.search,
			id: filterItem.name,
			options: filterItem.options.sort((n1, n2) =>
				n1.name > n2.name ? 1 : -1
			)
		}
	})
}

export const parseTableHeadData = (blok: any): TableHead => {
	const {
		tableDescription: blokDescription,
		tableDate,
		tableRequestedBy,
		tableStatus
	} = blok

	const tableDescription = blokDescription?.split('/')[0] ?? '[Description]'
	const tableSystem = blokDescription?.split('/')[1] ?? '[System]'

	return {
		color: 'black',
		columns: [
			{
				title: [tableDescription, tableSystem],
				field: tableColumnResolver.title,
				width: '30%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableDate,
				field: tableColumnResolver.dateFormatted,
				width: '15%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableRequestedBy,
				field: tableColumnResolver.requestedBy,
				width: '20%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableStatus,
				field: tableColumnResolver.status,
				width: '20%',
				sortIcon: true,
				icon: true
			}
		]
	}
}

export const buildTableBody = (
	partRequests: PartRequestData[],
	goToPartRequestsDetail: GoToDetailFnPartRequest,
	searchWord: string
): TableBodyData => {
	const tableBodyData: TableBodyData = {
		rows: [],
		total: partRequests.length
	}
	partRequests.forEach((item) => {
		tableBodyData.rows.push({
			id: item.id,
			onClick: goToPartRequestsDetail(item),
			columns: [
				{
					title: item.title,
					subline: '',
					searchWord
				},
				{
					title: item.dateFormatted,
					subline: '',
					searchWord
				},
				{
					title: item.requestedBy?.name,
					subline: '',
					searchWord
				},
				{
					title: (
						<Status
							text={item.status.name}
							state={caseStatusColorMapping[item.status.id]}
						/>
					),
					subline: '',
					searchWord
				}
			]
		})
	})

	return tableBodyData
}

export const onMobileSortingChanged = (
	ev: string,
	updateSorting: (
		property: PartRequestColumnKeys,
		order?: SortingType
	) => void
) => {
	let order: SortingType = null
	let property: PartRequestColumnKeys = null
	const orderValue = ev.toLowerCase()
	const propertyValue = orderValue
		.replace(`-${SortingType.asc}`, '')
		.replace(`-${SortingType.desc}`, '')

	if (orderValue.includes(SortingType.asc)) {
		order = SortingType.asc
	} else if (orderValue.includes(SortingType.desc)) {
		order = SortingType.desc
	} else if (orderValue.includes(SortingType.none)) {
		order = SortingType.none
	}

	if (propertyValue.includes(PartRequestColumnKeys.dateFormatted)) {
		property = PartRequestColumnKeys.dateFormatted
	} else if (propertyValue.includes(PartRequestColumnKeys.requestedBy)) {
		property = PartRequestColumnKeys.requestedBy
	} else if (propertyValue.includes(PartRequestColumnKeys.status)) {
		property = PartRequestColumnKeys.status
	} else if (propertyValue.includes(PartRequestColumnKeys.title)) {
		property = PartRequestColumnKeys.title
	}

	updateSorting(property, order)
}

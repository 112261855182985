import React, { useCallback } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useLocale } from '@hooks/useLocale'

import {
	GetPartRequestPageDataQuery,
	GetPartRequestPageDataDocument,
	Permissions,
	useGetAssetDetailsQuery
} from '@graphql/graphql'
import Banner from '@components/Banner'
import PageHead from '@components/PageHead'
import PartRequestOverview from '@components/PartRequestOverview'
import useAuthorization from '@hooks/useAuthorization'

import { PartRequestOverviewProps } from './types'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData = await apolloClient.query<GetPartRequestPageDataQuery>({
		query: GetPartRequestPageDataDocument,
		variables: {
			language: locale
		}
	})

	return pageData?.data?.PageItem ?? {}
}

const ComponentDictionary = {
	header: Banner,
	partRequestOverview: PartRequestOverview
}

const PartRequests = ({ pageData, assetId }: PartRequestOverviewProps) => {
	useAuthorization(Permissions.PartsRequestsRead)

	const { locale } = useLocale()

	// API calls
	// No error state as this data is only used to display the vessel name as the subtitle in the header
	// Therefore no suitable / usefull error state can be implemented
	const { data: assetDetailsData, loading: assetDetailsLoading } =
		useGetAssetDetailsQuery({
			variables: {
				id: assetId,
				language: locale
			},
			fetchPolicy: 'no-cache',
			skip: assetId == null
		})

	const subtitle = assetDetailsData?.asset?.name

	// Cached render component callback
	const renderComponent = useCallback(
		(blok: any) => {
			if (typeof ComponentDictionary[blok.component] !== 'undefined') {
				const props: any = {
					key: blok._uid,
					blok,
					loading: assetDetailsLoading
				}

				if (blok.component === 'header') {
					props.subtitle = subtitle
					props.sidebarBlocks = pageData?.content?.sidebarBlocks
				}

				if (blok.component === 'partRequestOverview') {
					props.assetId = assetId
					props.statusTitle =
						pageData?.content?.sidebarBlocks[0]?.title
				}

				return React.createElement(
					ComponentDictionary[blok.component],
					props
				)
			}

			return null
		},
		[pageData, subtitle, assetDetailsLoading, assetId]
	)

	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			{pageData?.content?.blocks.map((blok) => (
				<section key={blok._uid}>{renderComponent(blok)}</section>
			))}
		</>
	)
}

export default PartRequests
